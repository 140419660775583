import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

// create context
export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);

  const value = useMemo(() => {
    const getUsers = () => {
      return axios
        .get(
          `${apiUrl}/v1/users`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setUsers(response.data.users);
        });
    };

    const getUser = (userId) => {
      return axios
        .get(
          `${apiUrl}/v1/users/${userId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setUser(response.data);
        });
    };

    const deleteUser = (userId) => {
      return axios
        .delete(`${apiUrl}/v1/users/${userId}`, {})
        .then((response) => {
          console.log("user Deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const updateUserWalletById = (userId, wallet) => {
      const postData = {
        wallet: wallet,
      };

      return axios
        .put(`${apiUrl}/v1/users/${userId}`, postData)
        .then((response) => {
          console.log("wallet Updated::", response);
          getUsers();
          alert("Wallet updated!");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return {
      user,
      getUser,
      users,
      getUsers,
      deleteUser,
      updateUserWalletById,
    };
  }, [users, user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
