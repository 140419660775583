import "./Tracking.scss";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { AuthContext } from "../../context/AuthContext";
import React from "react";

// Define the Transaction interface
interface Transaction {
  txid: string; // Transaction ID
  sender: string; // Sender address (from the vin)
  amount: number; // Amount received
  confirmed: boolean; // Confirmation status
  block_time: string; // Block time
}

const Tracking = () => {
  const { userType } = useContext(AuthContext);
  const { user, getUser } = useContext(UserContext);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    getUser(userType.id);
  }, [userType]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Fetch confirmed transactions
        const allTransactions = await fetch(
          `https://mempool.space/api/address/${user?.wallet}/txs`
        );
        if (!allTransactions.ok)
          throw new Error("Failed to fetch confirmed transactions");
        const transactionsData = await allTransactions.json();

        const sortedTransactionsData = transactionsData.sort(
          (
            a: { status: { block_time: number } },
            b: { status: { block_time: number } }
          ) => b.status.block_time - a.status.block_time
        );

        // Combine confirmed and unconfirmed transactions
        const allTransactionArray = [...sortedTransactionsData]; // Unconfirmed first

        // Filter for receiving transactions specific to the user's wallet
        const receivingTransactions: Transaction[] = allTransactionArray.flatMap(
          (tx) => {
            const isReceivingTransaction =
              tx.vout.some(
                (output: { scriptpubkey_address: any }) =>
                  output.scriptpubkey_address === user?.wallet // Check if output address matches user's wallet
              ) &&
              !tx.vin.some(
                (input: { prevout: { scriptpubkey_address: any } }) =>
                  input.prevout.scriptpubkey_address === user?.wallet // Check if any input address matches user's wallet
              );

            if (isReceivingTransaction) {
              return tx.vout
                .filter(
                  (output: { scriptpubkey_address: any }) =>
                    output.scriptpubkey_address === user?.wallet
                ) // Filter outputs for user's wallet
                .map((output: { value: any }) => ({
                  txid: tx.txid,
                  sender: tx.vin[0]?.prevout?.scriptpubkey_address || "Unknown", // Assuming the first vin is the sender
                  amount: output.value / 100_000_000,
                  confirmed: tx.status.confirmed,
                  block_time: tx.status.block_time
                    ? new Date(tx.status.block_time * 1000).toLocaleString()
                    : "N/A", // Convert block_time to a readable format or "N/A" for unconfirmed
                }));
            }
            return []; // Return an empty array if not a receiving transaction
          }
        );

        if (receivingTransactions.length > 0) {
          setTransactions(receivingTransactions);
        }
      } catch (error) {
        console.error("Error fetching transaction history:", error);
      }
    };

    if (user?.wallet) {
      fetchTransactions(); // Initial fetch
      const intervalId = setInterval(fetchTransactions, 20000); // Poll every 10 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [user?.wallet]);

  return (
    <div className="transaction-history">
      <h1 className="title">Transaction History</h1> <br />
      <h2 className="wallet-address-t">{user?.wallet}</h2>
      {user?.wallet ? (
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Sender</th>
              <th>Amount</th>
              <th>Confirmed</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.txid} className="transaction-row">
                <td className="transaction-id">{transaction.txid}</td>
                <td className="transaction-sender">{transaction.sender}</td>
                <td className="transaction-amount">{transaction.amount}</td>
                <td className="transaction-confirmed">
                  {transaction.confirmed ? "Yes" : "No"}
                </td>
                <td className="transaction-block-time">
                  {transaction.block_time}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-wallet">No wallet connected.</p>
      )}
    </div>
  );
};

export default Tracking;
