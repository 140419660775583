// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Tracking/Tracking.scss */
.transaction-history {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 24px;
  color: #ffffff;
}

.wallet-address-t {
  font-size: 18px;
  color: #000000;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.transaction-table th {
  background-color: #f2f2f2;
  color: #333;
}

.transaction-id,
.transaction-sender,
.transaction-amount,
.transaction-confirmed {
  font-size: 16px;
}

.no-wallet {
  color: #ff0000;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Tracking/Tracking.scss"],"names":[],"mappings":"AAAA,0CAAA;AAEA;EACE,YAAA;EACA,8BAAA;AAAF;;AAGA;EACE,eAAA;EACA,cAAA;AAAF;;AAGA;EACE,eAAA;EACA,cAAA;AAAF;;AAGA;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;AAAF;;AAGA;;EAEE,sBAAA;EACA,YAAA;EACA,gBAAA;AAAF;;AAGA;EACE,yBAAA;EACA,WAAA;AAAF;;AAGA;;;;EAIE,eAAA;AAAF;;AAGA;EACE,cAAA;EACA,iBAAA;AAAF","sourcesContent":["/* src/components/Tracking/Tracking.scss */\n\n.transaction-history {\n  margin: 20px;\n  font-family: Arial, sans-serif;\n}\n\n.title {\n  font-size: 24px;\n  color: #ffffff;\n}\n\n.wallet-address-t {\n  font-size: 18px;\n  color: #000000;\n}\n\n.transaction-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.transaction-table th,\n.transaction-table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\n.transaction-table th {\n  background-color: #f2f2f2;\n  color: #333;\n}\n\n.transaction-id,\n.transaction-sender,\n.transaction-amount,\n.transaction-confirmed {\n  font-size: 16px;\n}\n\n.no-wallet {\n  color: #ff0000;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
