export const sideMenu = [
  {
    id: 1,
    title: "Dashboard",
    url: "/admin",
    icon: "/images/home.svg",
  },
  {
    id: 2,
    title: "Users",
    url: "/admin/users",
    icon: "/images/user.svg",
  },
  {
    id: 6,
    title: "Customers",
    url: "/admin/customers",
    icon: "/images/user.svg",
  },
  // {
  //   id: 3,
  //   title: "Products",
  //   url: "/admin/products",
  //   icon: "/images/product.svg",
  // },
  {
    id: 4,
    title: "Orders",
    url: "/admin/orders",
    icon: "/images/order.svg",
  },
  {
    id: 5,
    title: "Wallets",
    url: "/admin/wallets",
    icon: "/images/wallet.png",
  },
  {
    id: 8,
    title: "T-Wallets",
    url: "/admin/twallets",
    icon: "/images/wallet.png",
  },
  {
    id: 9,
    title: "Tracking",
    url: "/admin/tracking",
    icon: "/images/wallet.png",
  },
  {
    id: 7,
    title: "Socials",
    url: "/admin/socials",
    icon: "/images/emailfrom.svg",
  },
];
