// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-t {
  padding: 20px;
  border-radius: 8px;
}

.info-t {
  margin-bottom: 20px;
}

.wallets-wrap-t {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.wallet-network-t {
  flex: 1 1;
  font-weight: bold;
}

.wallet-address-t {
  flex: 2 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.wallet-button-t {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.wallet-button-t:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/TWallets/TWallets.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AACF;AACE;EACE,yBAAA;AACJ","sourcesContent":[".users-t {\n  padding: 20px;\n  border-radius: 8px;\n}\n\n.info-t {\n  margin-bottom: 20px;\n}\n\n.wallets-wrap-t {\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.wallet-network-t {\n  flex: 1;\n  font-weight: bold;\n}\n\n.wallet-address-t {\n  flex: 2;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  margin-right: 10px;\n}\n\n.wallet-button-t {\n  padding: 8px 12px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n\n  &:hover {\n    background-color: #0056b3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
