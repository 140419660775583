import { ReactNode, useContext } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Menu from "./components/Menu/Menu";
import Navbar from "./components/Navbar/Navbar";
import Orders from "./components/Orders/Orders";
import Products from "./components/Products/Products";
import Users from "./components/Users/Users";
import LoginPage from "./pages/LoginPage";
import Wallets from "./components/Wallets/Wallets";
import Socials from "./components/Socials/Socials";
import TWallets from "./components/TWallets/TWallets";
import Tracking from "./components/Tracking/Tracking";

import "./styles/global.scss";
import { AuthContext } from "./context/AuthContext";
import Customers from "./components/Customers/Customers";
import path from "path";

type ProtectedRouteProps = {
  // element?: ReactNode;
  redirectPath?: string;
  children?: ReactNode;
};

interface RouteObject {
  path?: string;
  element?: ReactNode;
  children?: RouteObject[];
}

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
//   const navigate = useNavigate();
//   const { user } = useContext(AuthContext);

//   useEffect(() => {
//     if (!user) {
//       navigate("/");
//     }
//   }, [navigate, user]);

//   if (!user) {
//     return <Navigate to={"/"} replace />;
//   }

// return <>{element}</>;
// };

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  // user,
  redirectPath = "/",
  children,
}) => {
  const { user } = useContext(AuthContext);
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

const App = () => {
  const Layout = () => {
    return (
      <div className="main">
        <Navbar />
        <div className="container">
          <div className="menuContainer">
            <Menu />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
      </div>
    );
  };

  // const protectedRoutes: RouteObject[] = [
  //   {
  //     path: "/admin",
  //     element: <Layout />,
  //     children: [
  //       {
  //         path: "/admin",
  //         element: <ProtectedRoute element={<Home />} />,
  //       },
  //       {
  //         path: "/admin/users",
  //         element: <ProtectedRoute element={<Users />} />,
  //       },
  //       {
  //         path: "/admin/products",
  //         element: <ProtectedRoute element={<Products />} />,
  //       },
  //       {
  //         path: "/admin/orders",
  //         element: <ProtectedRoute element={<Orders />} />,
  //       },
  //       // {
  //       //   path: "/users/:id",
  //       //   element: <Users />,
  //       // },
  //       // {
  //       //   path: "/products/:id",
  //       //   element: <Products />,
  //       // },
  //     ],
  //   },
  // ];

  const routes = [
    {
      path: "/",
      element: <LoginPage />,
    },
    // ...protectedRoutes,
    {
      path: "/admin",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      // element: <ProtectedRoute children={<Layout />} /> ,
      children: [
        {
          path: "/admin",
          element: <Home />,
        },
        {
          path: "/admin/users",
          element: <Users />,
        },
        {
          path: "/admin/customers",
          element: <Customers />,
        },
        {
          path: "/admin/products",
          element: <Products />,
        },
        {
          path: "/admin/orders",
          element: <Orders />,
        },
        {
          path: "/admin/wallets",
          element: <Wallets />,
        },
        {
          path: "/admin/socials",
          element: <Socials />,
        },
        {
          path: "/admin/twallets",
          element: <TWallets />,
        },
        {
          path: "/admin/tracking",
          element: <Tracking />,
        },
        // {
        //   path: "/users/:id",
        //   element: <Users />,
        // },
        // {
        //   path: "/products/:id",
        //   element: <Products />,
        // },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default App;
