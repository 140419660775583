import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

type Props = {
  slug: string;
  setPwdOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePassword = (props: Props) => {
  const [newPwd, setNewPwd] = useState("");
  const { changePassword } = useContext(AuthContext);
  const [confirmPwd, setConfirmPwd] = useState("");

  useEffect(() => {
    setNewPwd("");
    setConfirmPwd("");
  }, [props.slug]);

  const handleSubmit = (e: any, newPwd: any) => {
    changePassword(newPwd);
  };
  // //////////////////////
  return (
    <div
      className="add"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      onClick={() => props.setPwdOpen(false)}
    >
      <div
      className="modal"
      style={{ backgroundColor: 'white', padding: '25px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', position: 'relative' }}
      onClick={(e) => e.stopPropagation()}
      >
      <span
        className="close"
        onClick={() => props.setPwdOpen(false)}
        style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px', fontSize: '20px', color: '#000' }}
      >
        X
      </span>
      <h1 style={{ textAlign: 'center', marginBottom: '20px', marginTop: '10px', color: '#000' }}>{props.slug}</h1>

      <form
        onSubmit={(e) => {
        e.preventDefault();
        if (newPwd === confirmPwd) {
          handleSubmit(e, newPwd);
        } else {
          alert("Passwords do not match");
        }
        }}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <input
        type="password"
        placeholder="Enter new password"
        name="password"
        onChange={(e) => setNewPwd(e.target.value)}
        value={newPwd}
        style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
        <input
        type="password"
        placeholder="Retype new password"
        name="confirmPassword"
        onChange={(e) => setConfirmPwd(e.target.value)}
        value={confirmPwd}
        style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
        <button style={{ padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007bff', color: 'white', cursor: 'pointer' }}>
        Change password
        </button>
      </form>
      </div>
    </div>
  );
};

export default ChangePassword;
