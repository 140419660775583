import "./TWallets.scss";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";

interface User {
  id: string;
  username: string;
  wallet: string; // Assuming users have a wallet property
  role: string;
}

const TWallets = () => {
  const { users, getUsers, updateUserWalletById } = useContext(UserContext);
  const [tusers, setTUsers] = useState<User[]>([]);
  const [wallets, setWallets] = useState<{ [key: string]: string }>({}); // State to hold wallets for each user

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      const _users = users.filter((user: User) => user.role === "agent");
      setTUsers(_users);
      // Initialize wallets state for each user
      const initialWallets = _users.reduce(
        (acc: { [key: string]: string }, user: User) => {
          acc[user.id] = user.wallet; // Set initial wallet value
          return acc;
        },
        {} as { [key: string]: string }
      );
      setWallets(initialWallets);
    }
  }, [users]);

  const handleUpdate = async (id: string, wallet: string) => {
    if (wallet !== "") {
      try {
        await updateUserWalletById(id, wallet); // Assuming this function is defined
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="users-t">
      <div className="info-t">
        <h1>Agent Wallets</h1>
      </div>
      {tusers &&
        tusers.map((tuser: User, index: number) => {
          return (
            <div className="wallets-wrap-t" key={index}>
              <div className="wallet-network-t">{tuser.username}</div>

              <input
                className="wallet-address-t"
                value={wallets[tuser.id] || ""}
                onChange={(e) =>
                  setWallets({ ...wallets, [tuser.id]: e.target.value })
                }
                placeholder="Enter wallet address"
              />

              <button
                className="wallet-button-t"
                onClick={() => handleUpdate(tuser.id, wallets[tuser.id])}
              >
                Update
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default TWallets;
